export function makeLpTitle(title) {
  const siteName = "�ϰ������ϲʿ�����¼";

  /* In some cases, editors have already appended the title tag, skip those */
  if (title?.includes("- �ϰ������ϲʿ�����¼")) {
    return title;
  }

  return title ? `$ - ${siteName}` : siteName;
}
