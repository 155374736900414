import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { tagular } from "@cohesion/tagular";
import { ElsewhereLogoColor } from "@icons/logos";
import { Arrow } from "@icons/elsewhere";
import { Button } from "@components/button";
import { ImgixNextImage } from "@components/imgixNextImage";
import { useScreens } from "@utils/component";

import { Tabs } from "./tabs";

export function ElsewhereDriver() {
  const { screen } = useScreens();
  const isSmallScreen = ["sm", "md"].includes(screen);

  const copy = {
    default: {
      header: "Your dream itinerary, crafted with you",
      subhead:
        "Elsewhere by �ϰ������ϲʿ�����¼ connects you with an award-winning local expert to craft your personalized, unforgettable trip.",
      ctaCopy: "Explore Elsewhere",
      outboundUrl:
        "https://www.elsewhere.io/?utm_source=lonelyplanet&utm_medium=homepage&utm_campaign=homepage",
    },
  };

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      tagular("product_view", {
        product: {
          location: "HOMEPAGE MODULE_ELSEWHERE",
          text: copy.default.ctaCopy,
          position: 0,
          brand: "elsewhere",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <section ref={ref} className="bg-[#F0EBFE] lg:pt-[72px]">
      <div className="flex flex-col lg:flex-row-reverse lg:gap-x-14 xl:gap-x-20 2xl:gap-x-32">
        <div className="flex flex-col gap-3 px-6 py-8 md:container md:py-16 lg:px-0 lg:mr-14 lg:pt-0 lg:w-1/2 xl:mr-20 xl:w-7/12 2xl:mr-32">
          <div className="text-right mb-4 lg:text-left">
            <ElsewhereLogoColor className="inline-block text-[140px] md:text-[188px]" />
          </div>
          <h2 className="inline-block font-light w-full text-3xl text-transparent bg-clip-text bg-gradient-to-r from-[#1B5BFD] via-[#6A0DAD]  to-[#8C10F0] md:text-5xl lg:pb-4 xl:pb-6 lg:text-5xl">
            {copy.default.header}
          </h2>
          <p className="lg:text-xl lg:pb-6">{copy.default.subhead}</p>
          <Tabs />
          <Button
            href={copy.default.outboundUrl}
            onClick={() => {
              tagular("product_click", {
                actionOutcome: "EXTERNALLINK",
                product: {
                  location: "HOMEPAGE MODULE_ELSEWHERE",
                  text: copy.default.ctaCopy,
                  position: 0,
                  brand: "elsewhere",
                },
              });
            }}
            className="btn-primary inline-flex flex-shrink-0 items-center gap-2 w-content"
          >
            {copy.default.ctaCopy} <Arrow className="inline-block" />
          </Button>
        </div>
        <div className="relative w-full lg:w-1/2 xl:w-5/12">
          <ImgixNextImage
            alt="Local expert with a traveler in a forest"
            src="https://lonelyplanetstatic.imgix.net/marketing/2024/elsewhere/elsewhereDriver.jpg"
            width={isSmallScreen ? 390 : 500}
            height={isSmallScreen ? 360 : 700}
            imgixParams={{
              fit: "crop",
              auto: "format",
              w: isSmallScreen ? 390 : 500,
              h: isSmallScreen ? 360 : 700,
            }}
            className="rounded-tr-[64px] w-full h-[360px] md:h-[500px] lg:h-full xl:h-[750px] 2xl:h-full 2xl:max-h-[700px]"
          />
        </div>
      </div>
    </section>
  );
}
