import { useCallback, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { SectionHeading } from "@components/headings/section";
import { ImgixNextImage } from "@components/imgixNextImage";
import { tagular } from "@cohesion/tagular";
import { LP_SHOP_URL } from "@constants/lpUrl";

export function GuideBooks() {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      tagular("product_view", {
        product: {
          location: "HOMEPAGE MODULE_SHOP",
          text: "VIEW ALL BOOKS",
          position: 0,
          brand: "shop",
        },
      });
    }
  }, [inView]);

  const handleAllBooksClick = useCallback(() => {
    tagular("product_click", {
      actionOutcome: "INTERNALLINK",
      outboundUrl: LP_SHOP_URL,
      product: {
        location: "HOMEPAGE MODULE_SHOP",
        text: "VIEW ALL BOOKS",
        position: 0,
        brand: "shop",
      },
    });
  }, []);

  return (
    <div ref={ref} className="flex justify-center bg-black">
      <div className="w-full pb-20 md:pb-0 md:min-h-184 relative md:max-w-[1920px]">
        <div className="md:hidden">
          <ImgixNextImage
            src="https://lonelyplanetstatic.imgix.net/marketing/2022/BIT/bit-guidebook-bg-mobile.jpeg"
            width={420}
            height={260}
            imgixParams={{
              crop: "focalpoint",
              "fp-z": 1.325,
              "fp-x": 0.49,
              "fp-y": 0.79,
            }}
          />
        </div>
        <ImgixNextImage
          src="https://lonelyplanetstatic.imgix.net/marketing/2022/BIT/bit-guidebook-bg-desk.jpeg"
          width={1920}
          height={768}
          className="hidden md:block max-h-[800px]"
        />
        <div className="md:absolute md:top-0 md:left-0 md:mt-16 md:ml-16 container">
          <div className="text-white w-96 max-w-full">
            <p className="uppercase text-sm text-black-200 font-bold">
              shop our books
            </p>
            <SectionHeading className="pt-2">
              Our guidebooks &amp; travel books
            </SectionHeading>
            <p className="pt-4 pb-8 text-black-100 leading-relaxed">
              Whether you’re interested in traveling to a new city, going on a
              cruise, or cooking a new dish — we’re committed to inspiring you
              to experience travel in a whole new way. �ϰ������ϲʿ�����¼’s
              collection of 825+ travel and guidebooks is sure to inspire the
              traveler within.
            </p>
            <a
              href={`${LP_SHOP_URL}?utm_source=lonelyplanet&utm_campaign=homepage&utm_content=homepagemodule`}
              className="inline-block btn btn-inverted btn-border-white"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleAllBooksClick}
            >
              View All Books
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
